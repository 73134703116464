import React from 'react';
import Image from 'react-bootstrap/Image'
import YouTube from 'react-youtube';


import kokabil from '../img/melgraphics/kokabile_mentor_of_the_hidden_path_recadrage.png';
import oyama from '../img/melgraphics/o_yama_king_of_whispers_recadrage.png';

import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import ImageWebp from './ImageWebp';


class Description extends React.Component {
    render() {
        return (
            <div className="description_grid">
                <div className='description_text'>
                    <p className='description_title mt-3'>{ t('description_title') }</p>
                    <p className='subdescription_title'>{ t('description_subtitle') }</p>
                    <YouTube videoId="YgCxcoG5K64" className='video_container mb-3' />
                </div>
                <div className="description_left">
                    <ImageWebp className='description_image' object="graphics" image='kokabile_mentor_of_the_hidden_path_recadrage.png' alt='kokabile_mentor_of_the_hidden_path_recadrage.png' />
                </div >

                <div className="description_right">
                    <ImageWebp className='description_image' object="graphics" image='o_yama_king_of_whispers_recadrage.png' alt='o_yama_king_of_whispers_recadrage.png' />
                </div >
            </div >
        )
    }

}

export default withTranslation()(Description)

