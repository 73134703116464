import React, { createContext, useContext, useState } from 'react'

// creating context to use anywhere in app
const AuthContext = createContext()

// creating custom hook for discounts
export const useAuth = () => {
    return useContext(AuthContext)
}

// Creating Provider to wrap the App into so components will be able to access functions
export const AuthProvider = ({ children }) => {

    // Global State
    const [isLogin, setLogin] = useState(false)
    const [display, setDisplay] = useState('login_not') // accepts 'login_not' or 'login' to show Login frame

    // --------------- Login Functions -----------------------------
    // -------------------- API Call --------------------------------


    return (
        <AuthContext.Provider value={{ isLogin, setLogin, display, setDisplay }}>
            {children}
        </AuthContext.Provider>
    )
}

