import React, { useState, useRef, useEffect } from 'react';
import ImageWebp from './ImageWebp';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

const Login = () => {
    // Auth Context
    const { display, setDisplay } = useAuth()

    // URL handling
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');

    // state
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginFail, setLoginFail] = useState(false);

    // display
    const emailInput = useRef(null)
    const passwordInput = useRef(null)

    // translations
    const { t } = useTranslation()

    // focus input on open
    useEffect(() => {
        console.log(email)
        if(email !== null && email !== '') {
            setUsername(email)
            passwordInput.current.focus()
        } else {
            emailInput.current.focus()
        }
    }, [])

    const usernameHandler = (event) => {
        setUsername(event.target.value);
        setLoginFail(false);
    };

    const passwordHandler = (event) => {
        setPassword(event.target.value);
        setLoginFail(false);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        loginRequest();
    };

    const loginRequest = () => {
        try {
            fetch('/api.php', {
                method: 'POST',
                body: JSON.stringify({
                    action: 'login',
                    email: username,
                    password: password,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    if (response.status === 400 || response.status === 401) {
                        setLoginFail(true);
                    }
                })
                .then((data) => {
                    if (data.status !== undefined && data.status !== null && data.status !== '' && data.status !== 0) {
                        setLoginFail(false);
                        localStorage.setItem('email', data.email);
                        localStorage.setItem('token', data.status);
                        setDisplay('login_not')
                        window.location.href = '/account';
                    } else {
                        setLoginFail(true);
                    }
                });
        } catch (error) {
            if (error.status === 400 || error.status === 401) {
                setLoginFail(true);
            }
        }
    };

    const classname = 'login-form ' + display;
    let loginMessage = null;

    if (loginFail === true) {
        loginMessage = (
            <div className="login_alert alert alert-danger" role="alert">
                {t('login_wrong_credentials')}
            </div>
        );
    } else {
        loginMessage = <div></div>;
    }

    return (
        <form className={classname}>
            <div className="login_background"></div>
            <ImageWebp className="login_crossExit" object="graphics" image="croix.png" alt="croix.png" onClick={() => setDisplay('login_not')} />
            <h2 className="login_text">{t('menu_login_text')}</h2>
            {loginMessage}
            <div className="login_username_container20">
                <label>{t('login_register_email')}</label>
                <input ref={emailInput} className="login_fields" type="text" value={username} onChange={usernameHandler} />
            </div>
            <div className="login_password_container">
                <label>{t('login_register_pwd')}</label>
                <input ref={passwordInput} className="login_fields" type="password" value={password} onChange={passwordHandler} />
            </div>
            <div className="login_bouton">
                <div className="button gradientOrangePink buttonRadius buttonShadows" onClick={submitHandler}>
                    {t('menu_login_text')}
                </div>
            </div>
        </form>
    );
};

export default Login;
