import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from './ImageWebp';
import Login from './login';
import LanguageSelector from './languageSelector';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';

const Menu = () => {
    // Auth Context
    const { display, setDisplay } = useAuth()

    // state
    const [register, setRegister] = useState('register_not');

    // translations
    const { t } = useTranslation();

    const onLogin = () => {
        setDisplay('login');
        setRegister('register_not');
    };

    const onLoginExit = () => {
        setDisplay('login_not');
    };

    const onRegister = () => {
        setDisplay('login_not');
        setRegister('register');
    };

    const onRegisterExit = () => {
        setRegister('register_not');
    };

    const onAbalance = () => {
        window.location = 'https://www.abalance.ch';
    };

    const pathname = window.location.pathname;
    let menu_moncompte_class = 'menu_moncompte_display';
    let menu_login_register_class = 'menu_login_register_not_display';
    if (localStorage.getItem('token') === null) {
        menu_moncompte_class = 'menu_moncompte_not_display';
        menu_login_register_class = 'menu_login_register_display';
    }

    return (
        <div className='topMenu'>
            {display === 'login' && <Login />}
            <div className='menu_left px-sm-3'>
                <Link to='/'>
                    <ImageWebp object='graphics' image='menu_altar.png' alt='menu_altar.png' />
                </Link>
            </div>
            <Link to='' className='menu_right px-sm-3' onClick={onAbalance}>
                <ImageWebp fluid object='graphics' image='menu_ablance.png' alt='menu_ablance.png' />
            </Link>
            <LanguageSelector />
            <button onClick={onLogin} className={'menu_login ' + menu_login_register_class}>
                {t('menu_login_text')}
            </button>
            <Link onClick={onLogin}>
                <ImageWebp className={'icon_login ' + menu_login_register_class} fluid object='graphics' image='mobile_login.png' alt='mobile_login.png' width='35%' />
            </Link>
            <Link to='/account'>
                <ImageWebp className={'icon_moncompte ' + menu_moncompte_class} fluid object='graphics' image='mobile_compte.png' alt='mobile_compte.png' width='35%' />
            </Link>
            <Link to='/account' className={'menu_moncompte ' + menu_moncompte_class}>
                {t('menu_login_account')}
            </Link>
        </div>
    );
};

export default Menu;