import React from 'react'
import i18n from 'i18next'
import { Accordion } from 'react-bootstrap'

function localizedQuestions( array ) {
    const localizedQuestions = []
    const lng = i18n.resolvedLanguage ? i18n.resolvedLanguage.slice(0, 2) : 'en'
 
    array.map(item => {
        const formattedQuestion = {}
        const q_name = `${lng}_question`
        const q_text = `${lng}_answer`

        formattedQuestion.id = item.id
        formattedQuestion.question = item[q_name]
        formattedQuestion.answer = item[q_text]

        localizedQuestions.push(formattedQuestion)
    })

    return localizedQuestions
}

const FAQ = ({ questions }) => {
    const locFaq = localizedQuestions(questions)
    return (
        <div className='AccordionWrapper'>
            <h5>FAQ</h5>
            <Accordion defaultActiveKey="0" flush>
                {locFaq.map(faq =>
                    <Accordion.Item eventKey={faq.id}>
                        <Accordion.Button as="h4">{faq.question}</Accordion.Button>
                        <Accordion.Body>
                            {faq.answer}
                        </Accordion.Body>
                    </Accordion.Item>
                )}
            </Accordion>
        </div>
    )
}

export default FAQ