/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the validation after buying something
 *
 * @author Valentin Schneeberger
 * @since 14.06.2023
 */

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import Login from './login';
import ReturnButton from './returnButton';

// Translations Module
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';

const Validation = () => {
    // Auth context
    const {display, setDisplay} = useAuth()
    // const [login, setLogin] = useState('login_not');
    const { t } = useTranslation();

    const onAccountClick = () => {
        setDisplay('login');
    };

    const onLoginExit = () => {
        setDisplay('login_not');
    };

    const handleReturn = () => {
        document.location.href = '/';
    };

    return (
        <div className="validation_background">
            <Container>
                <div className='validation_container32'>
                    <div className='validation_centered gradientOrangePink boxShadows'>
                        <p>{t('validation_message')}</p>
                        <p>{t('validation_email')}<a href='mailto:communication@abalance.ch'>communication@abalance.ch</a></p>
                        <p>{t('validation_seeyou')}</p>
                    </div>
                    <div className='validation_accederImage gradientOrangePink buttonShadows' onClick={() => setDisplay('login')}>
                        {t('validation_account')}
                    </div>
                    <ReturnButton customClass="validation_retourImage" onClick={handleReturn} />
                    <div className='validation_login'>
                        <Login />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Validation;
