import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

const OverlayForm = ({ onClose, passedMessage = '' }) => {
  // translations
  const { t, i18n } = useTranslation()

  // Auth context
  const { setDisplay } = useAuth()

  // URL handling
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailParam = searchParams.get('email');
  const verify_key = searchParams.get('verify_key');
  const navigate = useNavigate()

  // logic
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false)
  const [sent, setSent] = useState(false)
  const [isUsed, setIsUsed] = useState(false)

  // display
  const emailInput = useRef(null)
  const [errorClass, setClass] = useState('')
  const [message, setMessage] = useState(passedMessage)
  const [colorClass, setColorClass] = useState('text-warning')

  // focus input on open
  useEffect(() => {
    emailInput.current.focus()
  }, [])

  // verify user if right params are in URL
  useEffect(() => {
    if (emailParam !== null && emailParam !== '' && emailParam !== 0 & verify_key !== null && verify_key !== '') {
      setEmail(decodeURIComponent(emailParam))
      verifyUser()
    }
  }, [])


  const verifyUser = async () => {
    try {
      // API Call
      const response = await fetch('/api.php', {
        method: 'POST',
        body: JSON.stringify({
          action: 'verify',
          email: decodeURIComponent(emailParam),
          verify_key: verify_key
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status !== undefined && data.status !== null) {
          if (data.status === 400) {
            // Invalid Key
            setColorClass('text-warning');
            setLoading(false);
            setIsUsed(true)
            setMessage(t('overlay_invalid_key'))
          } else if (data.status === 409) {
            // Already verifed
            setColorClass('text-light');
            setMessage(t('This account was already verified, you will be redirected shortly.'))

            localStorage.setItem('verified', true)
            setTimeout(() => {
              navigate('/download'); // Replace with your desired route
            }, 5000);
            setLoading(false);
          } else if (data.status === 200) {
            // Successfully Verified with link
            setLoading(false);
            setClass('');
            setMessage(t('Your account was verified successfully! You will now be redirected to download page'))

            localStorage.setItem('verified', true)
            setTimeout(() => {
              navigate('/download'); // Replace with your desired route
            }, 5000);
            setColorClass('text-light');
            setSent(true);
            return true;
          }
        } else {
          setLoading(false);
          setMessage('There was an error with the server');
        }
      } else {
        const status = response.status;
        if (status === 400 || status === 401) {
          setLoading(false);
          setMessage('Server error. Check your internet connection.');
        }
        throw new Error(`Server gave Error: ${status}. ${response}`);
      }
    } catch (error) {
      console.error('Error:', error.message);
      // Handle the error appropriately
    }
  };


  const handleOverlayClick = (e) => {
    // close overlay on click outside ONLY IF no fetch in progress
    if (!loading) {
      if (e.target.classList.contains('overlay-container')) {
        onClose();
      }
    }
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setClass('')
    setIsUsed(false);
  };

  const handleCheckboxChange = (e) => {
    setConsent(e.target.checked);
    setMessage(null)
    setIsUsed(false);
  }

  const handleToLogin = () => {
    onClose()
    if (email !== '') {
      // updates URL with email to pass to login frame
      navigate(`/?email=${email}`)
    }
    setDisplay('login')
  }

  const handleResetKey = async () => {
    // Loading state during API Call
    setIsUsed(false);
    setLoading(true)
    setColorClass('text-light');
    setMessage(t('overlay_sending_new_key'))
    
    sendConfirmationEmail(email, true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // form validation
    if (!consent) {
      setMessage(t('overlay_consent_required'))
      return;
    }

    if (email === '') {
      setClass('error')
      setMessage(t('overlay_email_required'))
      return;
    }

    // Loading state during API Call
    setLoading(true)
    setColorClass('text-light');
    setMessage(t('overlay_loading'))

    // API Call
    sendConfirmationEmail(email)

    // console.log('Submitted email:', email);
  };

  /** sendConfirmationEmail makes an API call that:
   *  1. checks if the email already exists in DB.
   *  2. creates a new account for the email if NOT. | checks if user is verified if exists
   *  3. sends an email to new user with verification link. | offer to send new email with new key if user exists but not verified
   * 
   *  The link can be used to make another API call to
   *    - validate account & allow access to download page.
   * @returns state from server if email was sent successfully and whatnot
   */
  const sendConfirmationEmail = async (email, repeat_email = false) => {
    try {
      // API Call
      const response = await fetch('/api.php', {
        method: 'POST',
        body: JSON.stringify({
          action: 'sendConfirmEmail',
          email: email,
          lang: i18n.resolvedLanguage.slice(0, 2),
          repeat_email: repeat_email
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status !== undefined && data.status !== null) {
          // user exists
          if (data.status === 409) {
            setColorClass('text-warning');
            setLoading(false);
            setIsUsed(true);
            setMessage(t('overlay_user_exists'));
          } else if (data.status === 201 || data.status === 200) {
            // Sending Email when New User
            setLoading(false);
            setClass('');
            setMessage(t('overlay_submitted', { email: email }));
            setColorClass('text-light');
            setSent(true);
            return true;
          } else if (data.status === 202) {
            // Redirects to download when Verified
            setClass('');
            setMessage(t('overlay_verified_redirection'));
            setColorClass('text-light');
            localStorage.setItem('verified', true)
            setTimeout(() => {
              navigate('/download');
            }, 3000);
          }
        }
      } else {
        const status = response.status;
        if (status === 400 || status === 401) {
          setLoading(false);
          setMessage('Network Error');
        }
        throw new Error(`Server gave Error: ${status}. ${response}`);
      }
    } catch (error) {
      console.error('Error:', error.message);
      // Handle the error appropriately
    }

  };

  return (
    <div className={`overlay-container`} onClick={handleOverlayClick}>
      <div className="overlay-box">
        <span className="close-icon" onClick={onClose}>
          <i className="bi bi-x-lg text-white"></i>
        </span>
        <form className="form-container" onSubmit={handleSubmit}>
          {!sent ?
            !loading ?
              <>
                <p>{t('overlay_cta')}</p>
                <div className="d-flex flex-column flex-sm-row justify-content-center">
                  <input
                    ref={emailInput}
                    type="email"
                    placeholder={t('login_register_email_addr')}
                    value={email}
                    onChange={handleInputChange}
                    className={`ps-3 col-md-8 overlay_input ${errorClass}`}
                  />
                  <button className="sub_dwld_button button gradientOrange text-black" onClick={() => handleSubmit}>{t('overlay_download')}</button>
                </div>
                <div className="d-flex flex-row align-items-md-center text-start">
                  <input
                    type="checkbox"
                    id='consent'
                    value={email}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="consent" className="ms-2">{t('newsletter_consent')}</label>
                </div>
              </>
              :
              <div className="fs-1 lds-dual-ring"></div>
            :
            <i className={`fs-1 bi bi-send-check ${colorClass}`}></i>
          }
          <p className={`${colorClass}`}>
            {message} {isUsed ?
              <span onClick={handleResetKey} className='asLink'>{t('overlay_new_email')}.</span>
              :
              null}
          </p>
        </form>
      </div>
    </div>
  );
};

export default OverlayForm;
