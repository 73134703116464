
import React from 'react';

import Checkout from "../components/checkout";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const items = [{id:"all_pulses" }]
    
export default function checkout({ allProducts }) {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('page_checkout')} - Altar</title>
            </Helmet>
            <Checkout allProducts={allProducts} items={{items}} > </Checkout>
        </>
    );
};