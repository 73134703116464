import React from 'react';
// import { wrapGrid } from 'animate-css-grid'

import { OverlayTrigger, Popover } from 'react-bootstrap';

import Product from './product';
import AltarCard from './card';
import CardList from './expansionCarousel';
import Cart from './cart';
// import ResponsiveSlider from './slider';

import withDiscount from './HOC/withDiscounts'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// Translations
import i18n, { t } from 'i18next';
import ImageWebp from './ImageWebp';
import FAQ from './faq';

class Buy extends React.Component {

    constructor(props) {
        super(props)
        this.handleLeft = this.handleLeft.bind(this)
        this.handleRight = this.handleRight.bind(this)
        this.handleToCheckout = this.handleToCheckout.bind(this)
        this.hideAndShowText = this.hideAndShowText.bind(this)

        this.state = {
            baseProducts: [], // list of products
            expansions: [],
            selectedProduct: null, // selectedProduct in state
            selectedExpansion: null,
            currentPdtPosition: 0,
            currentExpPosition: 0,
            bought: [], // list of bought items
            touchStartX: null,
            touchEndX: null,
            isOpen: false, // show more button on mobile
            modalShow: false,
            price: 0
        };

    }

    componentDidMount = () => {
        this.fetchTranslatedProducts()
        i18n.on('languageChanged', this.fetchTranslatedProducts)
    }

    componentWillUnmount = () => {
        // console.log('buy unmounted')
        i18n.off('languageChanged', this.fetchTranslatedProducts)
    }

    fetchTranslatedProducts = () => {
        const products_t = t('pulsesInfos', { ns: 'cards', returnObjects: true });
        var lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'

        fetch('/api.php', {
            method: 'POST',
            body: JSON.stringify({
                action: 'getAvailableProduct',
                lang: lng
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server gave Error:' + response.status);
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data) {
                    // console.log(data)
                    if (data.AvailableProduct) {
                        // console.log('fetch data: ', data.AvailableProduct)

                        // filtering all products to base decks
                        const productsData = data.AvailableProduct.filter(p => p.ename === 'Base')

                        const productsValues = this.formatProducts(productsData)

                        // drawing base products selection component
                        this.setProducts(productsData, 'baseProducts', true)

                        // filtering all products to base expansions
                        const expansionsData = data.AvailableProduct.filter(e => e.ename !== 'Base')
                        // console.log('expansions: ' + expansionsData)

                        if (expansionsData.length > 0) {
                            const expansionValues = this.formatProducts(expansionsData)
                            // console.log(expansionValues)
                            this.setState({ expansions: expansionValues }) // per use with global cart
                        }

                    } else {
                        //set error
                    }
                }
            })
            .catch(error => {
                console.error('Products could not be fetched:', error)
                this.setProducts(Object.values(products_t), 'baseProducts')
            })

    }

    formatProducts(array, sortByDate = false) {
        const lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en'
        var formatedArray = []
        let sortedArray = []

        // setting dynamic property name for potential future language implementation - provided the entry exists in the database and is fetched
        const p_lng_name = `p_${lng}_name`
        const p_lng_description = `p_${lng}_description`

        array.forEach(item => {
            const pname = item[p_lng_name]
            const pdescription = item[p_lng_description]
            const ptype = item.ename
            const pcost = (item.cost / 100)
            const pdateString = item.start_date

            const product = {}

            product.id = item.id
            product.image = item.image
            product.title = pname
            product.description = pdescription
            product.cost = pcost
            product.type = ptype
            product.date = new Date(pdateString)
            product.startdate = this.props.onFormatDate ? this.props.onFormatDate(item.start_date, false) : item.start_date // onFormatDate( array, displayHour = true )

            formatedArray.push(product)
        })

        if (sortByDate) {
            formatedArray.sort((a, b) => b.date - a.date) // sorting expansions by descending Date (more recent first)
        } else {
            sortedArray = formatedArray
        }

        // console.log(formatedArray)
        return formatedArray
    }

    // NEW function after products migrated cross App
    // updateProducts = () => {
    //     const { products } = this.props
    //     const baseProducts = products.filter(p => p.type === 'Base')
    //     const expProducts = products.filter(p => p.type !== 'Base')
    //     this.setState({
    //         products: baseProducts,
    //         expansions: expProducts
    //     })
    // }

    handleModal(value) {
        // console.log('New value of modal is:', value)

        this.setState({ modalShow: value })
    }

    /* setProducts - used to setState using Slider. Arguments :
        array : array used for proceesing (either from fetch or local translations)
        isFetched : used to determine which object properties are used for render - render local data if error
        component : used to get last position the component was in before update
    */
    setProducts(array, component, isFectched = false) {
        var lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'
        var baseProducts = []
        var expansions = []

        const { currentPdtPosition, currentExpPosition } = this.state

        let productsValues = array
        let midArrayPos = Math.floor(array.length / 2)
        let basePosition = midArrayPos !== 0 ? -(midArrayPos) : midArrayPos;

        let currentPosition = 0
        switch (component) {
            case 'baseProducts':
                // console.log('Unprocessed products are: ', array)
                currentPosition = currentPdtPosition
                break;
            case 'expansions':
                // console.log('Unprocessed products are: ', array)
                currentPosition = currentExpPosition
                break;
            default:
                break;
        }
        let selectLastPos = midArrayPos + 1 + currentPosition

        // console.log('selectLastPos: ', selectLastPos)

        const sortedProducts = this.handleProductPosition(productsValues, currentPosition)

        sortedProducts.map(productItem => {
            var pname = productItem.title
            var pdescription = productItem.text
            let isBought = false
            if (this.state.bought !== null && this.state.bought.find(boughtItem => boughtItem.props.id === productItem.id)) {
                isBought = true
            }

            var ptype = ''
            var pcost = 24.90
            if (isFectched) {
                const p_lng_name = `p_${lng}_name`
                const p_lng_description = `p_${lng}_description`
                pname = productItem[p_lng_name]
                pdescription = productItem[p_lng_description]
                ptype = ' - ' + productItem.ename
                pcost = (productItem.cost / 100)
            }

            const selectedClass = basePosition === 0 && 'product_selected'
            // console.log('array length: ' + sortedProducts.length + ' - maxRight:' + midArrayPos)
            const hiddenItemClass = basePosition > midArrayPos - 1 && 'hidden'
            const productClasses = selectedClass + ' ' + hiddenItemClass

            const product = (
                <Product
                    key={'product_' + productItem.id}
                    class={productClasses}
                    img={productItem.image}
                    position={basePosition}
                    title={pname + ptype}
                    text={pdescription}
                    cost={pcost}
                    isBought={isBought}
                    id={productItem.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
            switch (component) {
                case 'baseProducts':
                    baseProducts.push(product)
                    break;
                case 'expansions':
                    expansions.push(product)
                    break;
                default:
                    break;
            }
            basePosition++
        });

        switch (component) {
            case 'baseProducts':
                const selectedProduct = baseProducts.find(productItem => productItem.props.id === selectLastPos);
                this.setState({
                    baseProducts: baseProducts,
                    selectedProduct: selectedProduct
                });
                break;
            case 'expansions':
                const selectedExpansion = expansions.find(productItem => productItem.props.position === selectLastPos);
                this.setState({
                    expansions: expansions,
                    selectedExpansion: selectedExpansion
                });
                break;
            default:
                break;
        }


        // console.log('selectedExpansion is: ', this.state.selectedExpansion)

    }

    handleProductPosition = (array, lastPos) => {
        const firstPart = array.slice(lastPos);
        const secondPart = array.slice(0, lastPos);

        return firstPart.concat(secondPart);
    }

    updatePrice = (newCart) => {
        if (newCart.length === 0) {
            return 0
        }
        const { database, onComputePrice } = this.props
        // extract IDs from Cart - newCart format : [{id:1, img:..., title:..., ...props}, {id:2, ...}] 
        const newCartIDs = newCart.map(product => product.id)
        return onComputePrice(newCartIDs, database)
    }

    handleRemove = (elem, component) => {
        const { baseProducts, expansions } = this.state
        const { cart, setCart, database } = this.props

        var array = []
        var products2 = []
        var newBought = []
        var selected = []
        var newCart = []
        var count = 0
        var newPrice = 0

        // update Base Product slider
        baseProducts.forEach(p => {
            var nBought = p.props.id === elem.props.id ? false : p.props.isBought
            var p2 = <Product
                img={p.props.img}
                position={p.props.position}
                title={p.props.title}
                text={p.props.text}
                isBought={nBought}
                onBoughtList={false}
                id={p.props.id}
                handleRemove={(e) => this.handleRemove(e, component)}
            />

            products2.push(p2)
            if (p.props.position == 0) {
                selected = p2
            }
        })

        // updating Cart
        newCart = cart.filter(cartItems => cartItems.id !== elem.props.id)
        // updating global Cart state
        setCart(newCart)

        // updating cart items position
        let trimBought = cart.filter(cartItems => cartItems.id !== elem.props.id)
        trimBought.forEach(p => {
            var p3 = <Product
                img={p.img}
                position={count}
                title={p.title}
                text={p.text}
                isBought={true}
                onBoughtList={true}
                id={p.id}
                handleRemove={p.id < 7 ? (e) => this.handleRemove(e, component) : () => this.removeFromCart(p.id)}
            />
            count += 1
            newBought.push(p3)
        })

        // updating price
        newPrice = database !== null ? this.updatePrice(newCart) : 0

        this.setState(prevState => ({
            [component === 'baseProducts' ? 'baseProducts' : 'expansions']: products2,
            [component === 'baseProducts' ? 'selectedProduct' : 'selectedExpansion']: selected,
            bought: newBought,
            price: newPrice
        }));

    }

    // refactored logic for remove from cart for simlicity purposes. Not yet Used
    removeFromCart = (productId) => {
        const { cart, setCart, database } = this.props
        let newBought = []
        let newPrice = 0

        // const itemToRemove = bought.find(cartItem => cartItem.props.id === productId)
        const newCart = cart.filter(cartItems => cartItems.id !== productId)

        // Updating global cart state
        setCart(newCart)

        // updating cart items position
        newCart.forEach((p, count) => {
            var p3 = null
            // refresh base products in cart
            if (p.id < 7) {
                p3 = <Product
                    img={p.img}
                    position={count}
                    title={p.title}
                    text={p.text}
                    isBought={true}
                    onBoughtList={true}
                    id={p.id}
                    handleRemove={(e) => this.handleRemove(e, 'baseProducts')}
                />
                // refresh expansions in cart
            } else {
                p3 = <Product
                    img={p.img}
                    position={count}
                    title={p.title}
                    text={p.text}
                    isBought={true}
                    onBoughtList={true}
                    id={p.id}
                    handleRemove={() => this.removeFromCart(p.id)}
                />
            }
            newBought.push(p3)
        })

        // updating price
        newPrice = database !== null ? this.updatePrice(newCart) : 0

        this.setState({
            bought: newBought,
            price: newPrice
        })
        // localStorage.setItem('bought', newCart)
    }

    handleRight(event, component) {
        const { baseProducts, expansions, currentPdtPosition, currentExpPosition } = this.state

        var array = []
        var currentPosition = 0
        var products2 = []
        switch (component) {
            case 'baseProducts':
                currentPosition = currentPdtPosition
                products2 = [...baseProducts]
                array = baseProducts
                break;
            case 'expansions':
                currentPosition = currentExpPosition
                products2 = [...expansions]
                array = expansions
                break;
            default:
                break;
        }
        var select

        let mostRightPos = Math.ceil(baseProducts.length / 2)  // +2 for 6
        let mostLeftPos = -Math.floor(baseProducts.length / 2) // -3 for 6

        // determines current position to keep when lang is changed
        var currentPos = currentPosition === mostRightPos - 1 ? mostLeftPos : currentPosition + 1


        array.forEach((p, index) => {
            var oldPosition = p.props.position

            if (oldPosition === mostLeftPos) {
                oldPosition = mostRightPos
            }
            if (oldPosition === 1) {
                select = products2[index]

            }
            products2[index] = (
                <Product
                    img={p.props.img}
                    position={oldPosition - 1}
                    title={p.props.title}
                    text={p.props.text}
                    isBought={p.props.isBought}
                    id={p.props.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
        })


        this.setState({
            [component === 'baseProducts' ? 'baseProducts' : 'expansions']: products2,
            [component === 'baseProducts' ? 'selectedProduct' : 'selectedExpansion']: select,
            [component === 'baseProducts' ? 'currentPdtPosition' : 'selectedExpansion']: currentPos
        });


    }
    handleLeft(event, component) {
        const { baseProducts, expansions, currentPdtPosition, currentExpPosition } = this.state

        var array = []
        var currentPosition = 0
        var products2 = []
        var select;
        switch (component) {
            case 'baseProducts':
                currentPosition = currentPdtPosition
                products2 = [...baseProducts]
                array = baseProducts
                break;
            case 'expansions':
                currentPosition = currentExpPosition
                products2 = [...expansions]
                array = expansions
                break;
            default:
                break;
        }

        let mostRightPos = Math.ceil(this.state.baseProducts.length / 2)
        let mostLeftPos = -Math.floor(this.state.baseProducts.length / 2)

        // determines current position to keep when lang is changed
        var currentPos = currentPosition === mostLeftPos ? mostRightPos - 1 : currentPosition - 1

        array.forEach((p, index) => {
            var oldPosition = p.props.position
            if (oldPosition === mostRightPos) {
                oldPosition = mostLeftPos
            }
            if (oldPosition === -1) {
                select = products2[index]
            }
            products2[index] = (
                <Product
                    img={p.props.img}
                    position={oldPosition + 1}
                    title={p.props.title}
                    text={p.props.text}
                    isBought={p.props.isBought}
                    id={p.props.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
        })


        this.setState({
            [component === 'baseProducts' ? 'baseProducts' : 'expansions']: products2,
            [component === 'baseProducts' ? 'selectedProduct' : 'selectedExpansion']: select,
            [component === 'baseProducts' ? 'currentPdtPosition' : 'selectedExpansion']: currentPos
        });

    }

    // updated function for addToCart any product; base or upcoming expansion. Currently used for expansions
    addToCart = (productId) => {
        const { expansions, bought } = this.state
        const isProductInCart = bought.find(cartItem => cartItem.props.id === productId) // to be changed with cart state with only values and no JSX
        const { setCart, database } = this.props
        var newPrice = 0

        if (!isProductInCart) {
            const newCartItem = expansions.find(exp => exp.id === productId)

            // temporary code below for current Cart Logic. Later, only state will be changed
            // const img1 = require('../img/pulses/' + newCartItem.image)
            const newBought = <Product
                key={newCartItem.id}
                img={newCartItem.image}
                position={bought.length}
                title={newCartItem.title}
                text={newCartItem.description}
                isBought={true}
                onBoughtList={true}
                id={newCartItem.id}
                handleRemove={() => this.removeFromCart(newCartItem.id)}
            />
            //END of temporary code

            // adding product to global cart
            const prevCart = bought.map(p => p.props)
            const newCart = [...prevCart, newBought.props]

            // Updating global cart state
            setCart(newCart)

            newPrice = database !== null ? this.updatePrice(newCart) : 0

            this.setState(prevState => ({
                bought: [...prevState.bought, newBought],
                price: newPrice
            }))
        }
    }

    handleAddToCart = (component) => {
        const { selectedProduct, selectedExpansion } = this.state
        const { setCart, database } = this.props
        var selectedItem = {}
        switch (component) {
            case 'baseProducts': selectedItem = selectedProduct; break;
            case 'expansions': selectedItem = selectedExpansion; break;
            default:
                break;
        }
        if ((selectedItem && !selectedItem.props.isBought)) {
            const { baseProducts, expansions, bought } = this.state
            let isItemInCart = false
            let item = {}
            let array = []
            var product2 = []
            var newBought = []
            var selected = []
            var newPrice = 0

            switch (component) {
                case 'baseProducts':
                    item = baseProducts.find((productItem) => productItem.props.position === 0)
                    isItemInCart = bought.find((cartItem) => cartItem.props.id === item.props.id);
                    array = baseProducts
                    break;
                case 'expansions':
                    item = expansions.find((expansionItem) => expansionItem.props.position === 0)
                    isItemInCart = bought.find((cartItem) => cartItem.props.id === item.props.id);
                    array = expansions
                    break;

                default:
                    break;
            }

            array.forEach(item => {
                var nBought = item.props.position == 0 ? true : item.props.isBought
                if (nBought) {
                    const p2 = (
                        <Product
                            key={item.props.title + item.props.id + '_bought'}
                            img={item.props.img}
                            position={bought.length}
                            title={item.props.title}
                            text={item.props.text}
                            isBought={nBought}
                            onBoughtList={true}
                            id={item.props.id}
                            handleRemove={(e) => this.handleRemove(e, component)}
                        />
                    )
                    if (item.props.position === 0) {
                        newBought = p2
                    }
                }

                const p = (
                    <Product
                        key={item.props.title + item.props.id}
                        img={item.props.img}
                        position={item.props.position}
                        title={item.props.title}
                        text={item.props.text}
                        isBought={nBought}
                        onBoughtList={false}
                        id={item.props.id}
                        handleRemove={(e) => this.handleRemove(e, component)}
                    />
                )

                if (item.props.position === 0) {
                    selected = p
                }

                product2.push(p)
            })


            if (!isItemInCart) {
                // adding product to global cart
                const prevCart = bought.map(p => p.props)
                const newCart = [...prevCart, newBought.props]

                // Updating global cart state
                setCart(newCart)

                newPrice = database !== null ? this.updatePrice(newCart) : 0

                this.setState((prevState) => ({
                    [component === 'baseProducts' ? 'baseProducts' : 'expansions']: product2,
                    [component === 'baseProducts' ? 'selectedProduct' : 'selectedExpansion']: selected,
                    bought: [...prevState.bought, newBought],
                    price: newPrice
                }))

            }
        }
    }

    handleToCheckout() {
        if (this.state.bought.length > 0) {
            const { cart, navigate } = this.props // cart updater from global Context
            const cartIDs = cart.map(product => product.id)

            // Use navigate to go to checkout page WITHOUT reloading - necessary to keep app state intact
            // const tid = JSON.stringify(cartIDs).toString().slice(1, -1);
            const tid = cartIDs.join(',');
            // window.location.href = '/checkout?id=' + tid
            navigate(`/checkout?id=${tid}`)
        }
    }

    handleTouchStart = (event) => {
        const touch = event.touches[0];

        this.setState({ touchStartX: touch.clientX });
    };

    handleTouchMove = (event) => {
        if (!this.state.touchStartX) return;

        const touch = event.touches[0];


        this.setState({ touchEndX: touch.clientX });
    };

    handleTouchEnd = () => {
        if (!this.state.touchStartX || !this.state.touchEndX) return;

        const treshold = 70;

        const swipeDistance = this.state.touchEndX - this.state.touchStartX;

        if (swipeDistance > treshold) {
            this.handleLeft();
        } else if (swipeDistance < -treshold) {
            this.handleRight();
        }



        this.setState({
            touchStartX: null,
            touchEndX: null,
        });
    };

    hideAndShowText() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    getProductCards = (productId) => {
        const { cards } = this.props // passed from Main.jsx state
        // console.log('props cards: ', cards)

        var cardList = []

        // filter all cards to match cards from product
        cardList = cards.filter(c => c.product === productId)

        return cardList
    }

    drawDecks = (max) => {
        const decks = ['truth.png', 'lies.png', 'order.png', 'chaos.png', 'life.png', 'death.png']

        const jsxReturn = [];

        for (let i = 0; i < max; i++) {
            // jsxReturn.push(<ImageWebp object='decks' image={decks[i]} alt={decks[i]} />)
            jsxReturn.push(<i class="bi bi-collection-fill mx-2"></i>)
            i !== max - 1 && jsxReturn.push('+')
        }

        // for (let i = 0; i < max; i++) {
        //     jsxReturn.push(<i key={i} className="bi bi-box"></i>);
        //     i !== max - 1 && jsxReturn.push('+')
        // }

        return jsxReturn;
    }

    render() {
        const { isOpen, baseProducts, expansions, selectedProduct, selectedExpansion, bought } = this.state;
        const open = isOpen ? "open" : "close"
        const text = isOpen ? t('buy_status_text_open') : t('buy_status_text_close')

        const arrFaq = [
            {
                id: "1",
                fr_question: "Combien de cartes y-a-t'il dans un produit?",
                en_question: "How many cards is there in a product?",
                fr_answer: "Les produits de base sont composés de 20 cartes d'une pulse. Les extensions quant à elles peuvent contenir de 3 à 10 cartes provenant de pulses différentes.",
                en_answer: "Base products consist of 20 cards from one pulse. Extensions, on the other hand, can contain from 3 to 10 cards from different pulses."
            },
            {
                id: "2",
                fr_question: "Puis-je imprimer les cartes sur n'importe quel épaisseur de papier?",
                en_question: "Can I print cards on any paper?",
                fr_answer: "Pour les meilleurs résultats, nous recommandons du papier au minimum 180gr/m2. Si vous n'y avez pas accès, vous pouvez également imprimer sur du papier standard et placer du carton derrière la carte dans la sleeve.",
                en_answer: "For best results, we recommend 180gr/m2 paper. If you don't have access to this paper, you may want to print it on standard paper and place a piece of cardboard behind the card in your sleeve."
            },
            {
                id: "3",
                fr_question: "Où puis-je acheter des sleeve?",
                en_question: "Where can I buy sleeves for my Altar deck?",
                fr_answer: "Nous recommandons [InsererSite]. Si vous le souhaitez, vous pouvez aussi acheter nos [sleeves Pulse personalisées] avec votre pulse favorite.",
                en_answer: "We recommend [InsertSite]. If you like, you may also purchase our [custom Pulse Sleeves] from your favrotie Pulse."
            }
        ]

        var productCardsBase = []
        var productCardsExp = []

        // selectedProduct !== undefined && console.log(selectedProduct)
        productCardsBase = selectedProduct !== null && this.getProductCards(selectedProduct.props.id)

        var lng = localStorage.getItem('i18nextLng') !== undefined ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'

        // Product Cards Tooltip
        const popoverBase = (
            <Popover className="buy_product_cards" id="popover-products">
                <Popover.Header as="h3">{t('buy_popover_header')}</Popover.Header>
                <Popover.Body>
                    {t('buy_popover_text')}
                    <div className='buy_product_cardsBox'>
                        {productCardsBase && productCardsBase.map(c => {
                            return (
                                <AltarCard
                                    key={'p_' + c.image}
                                    title={c.title}
                                    text={''}
                                    image={c.image}
                                    alt={c.image}
                                    pulse={c.pulse}
                                />
                            )
                        })}
                    </div>
                </Popover.Body>
            </Popover>
        )

        const popoverExp = (
            <Popover className="buy_product_cards" id="popover-products">
                <Popover.Header as="h3">{t('buy_popover_header')}</Popover.Header>
                <Popover.Body>
                    {t('buy_popover_text')}
                    <div className='buy_product_cardsBox'>
                        {productCardsExp && productCardsExp.map(c => {
                            const img1 = require('../img/pulses/' + c.pulse + '/sample/' + lng + '/' + c.image)
                            return (
                                <AltarCard
                                    key={'p_' + c.image}
                                    title={c.title}
                                    text={''}
                                    image={img1}
                                    alt={c.image}
                                    pulse={c.pulse}
                                />
                            )
                        })}
                    </div>
                </Popover.Body>
            </Popover>
        )

        // getting discounts and compute_price functions from context
        const { cart, database, onComputePrice } = this.props

        var items = []
        var l1 = 0
        var l2 = 0
        var l3 = 0
        var l4 = 0
        var l5 = 0
        var l6 = 0

        if (database != null) {
            items = [1]
            l1 = onComputePrice(items, database)
            items = [1, 2]
            l2 = onComputePrice(items, database)
            items = [1, 2, 3]
            l3 = onComputePrice(items, database)
            items = [1, 2, 3, 4]
            l4 = onComputePrice(items, database)
            items = [1, 2, 3, 4, 5]
            l5 = onComputePrice(items, database)
            items = [1, 2, 3, 4, 5, 6]
            l6 = onComputePrice(items, database)

        }

        const { price } = this.state

        const { products } = this.props
        // console.log(baseProducts)

        return (
            <div id="purchase" className='buy_container'>
                <div className='buy_info_container gradientOrangePink boxShadows buttonRadius text-dark fs-4 py-5 p-md-5 p-3'>
                    {/* <span className='fw-light'><i className="bi bi-plus-slash-minus px-2"></i>{t('buy_info_pre_title')}</span> */}
                    <div className="buy_info_wrapper">
                        <h4>{t('buy_info_heading')}</h4>
                        <ul className='text-start'>
                            <li><i className="bi bi-collection-fill mx-2"></i>{t('buy_info_base_1')}</li>
                            <li><i className="bi bi-printer-fill mx-2"></i>{t('buy_info_base_2')}</li>
                            <li><i className="bi bi-stack mx-2"></i>{t('buy_info_base_3')}<small></small></li>
                            <li><i className="bi bi-people-fill mx-2"></i>{t('buy_info_base_4')}</li>
                            <li><i className="bi bi-trophy-fill mx-2"></i>{t('buy_info_base_5')}</li>
                        </ul>
                    </div>
                    {/* <hr /> */}
                    {/* <FAQ questions={arrFaq} /> */}
                </div>
                {/* Discount Container */}
                <div className='buy_rabais_container buy_rabais_image'>
                    <div className='text-center buy_rabais_text_container'>
                        <span className='text-center fw-light'><i className="bi bi-plus-slash-minus px-2"></i>{t('buy_info_pre_title')}</span>
                        <h4>{t('buy_info_discount')}</h4>
                        <i class="bi bi-collection-fill mx-2"> : {t('buy_info_legend')}</i>
                        <p>
                            <span className='buy_rabais_decks'>{this.drawDecks(1)}</span><span className='buy_rabais_price'>{l1} CHF</span>
                        </p>
                        <p>
                            <span className='buy_rabais_decks'>{this.drawDecks(2)}</span><span className='buy_rabais_price'>{l2}  CHF <s className='fw-lighter fst-italic'>{(l1 * 2).toFixed(2)} CHF</s></span>
                        </p>
                        <p>
                            <span className='buy_rabais_decks'>{this.drawDecks(3)}</span><span className='buy_rabais_price'>{l3}  CHF <s className='fw-lighter fst-italic'>{(l1 * 3).toFixed(2)} CHF</s></span>
                        </p>
                        <p>
                            <span className='buy_rabais_decks'>{this.drawDecks(4)}</span><span className='buy_rabais_price'>{l4}  CHF <s className='fw-lighter fst-italic'>{(l1 * 4).toFixed(2)} CHF</s></span>
                        </p>
                        <p>
                            <span className='buy_rabais_decks'>{this.drawDecks(5)}</span><span className='buy_rabais_price'>{l5}  CHF <s className='fw-lighter fst-italic'>{(l1 * 5).toFixed(2)} CHF</s></span>
                        </p>
                        <p>
                            <span className='buy_rabais_decks'>{this.drawDecks(6)}</span><span className='buy_rabais_price'>{l6}  CHF <s className='fw-lighter fst-italic'>{(l1 * 6).toFixed(2)} CHF</s></span>
                        </p>
                    </div>
                </div>

                {/* Base Products Carousel */}
                <div className='buy_select_container' onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
                    <h2 className='p-2'>{t('buy_base_products')}</h2>
                    <div className="buy_select_products_wrapper">
                        {baseProducts}
                    </div>

                    <div className='buy_select_title_container'>
                        <div className='buy_select_left buy_select_controls' onClick={(e) => { this.handleLeft(e, 'baseProducts') }}>
                            <i className="bi bi-caret-left-fill"></i>
                        </div>
                        <div className='buy_select_right buy_select_controls' onClick={(e) => { this.handleRight(e, 'baseProducts') }} >
                            <i className="bi bi-caret-right-fill"></i>
                        </div>
                        <div className='buy_select_title'>
                            {selectedProduct && (
                                <>
                                    {selectedProduct.props.title}
                                    <OverlayTrigger trigger={['click', 'focus']} placement="top" overlay={popoverBase} rootClose>
                                        <span className='text-decoration-underline'><i className="bi bi-question-circle mx-2"></i><span className='text-decoration-none'>{t('buy_product_cards')}</span></span>
                                    </OverlayTrigger>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={'buy_selected_text_container buy_selected_text_container_' + open}>

                        <div className={'buy_selected_text buy_selected_text_' + open} id='selected_text'>
                            {selectedProduct && selectedProduct.props.text}
                        </div>
                        <button className="button_show" id='selected_btn' onClick={this.hideAndShowText}>{text}</button>
                    </div>

                    <div className={('buy_select_button_valider_container')}>
                        <div
                            className={('buy_select_button_valider_text buy_select_button_valider_image button buttonShadows gradientOrangePink') + (selectedProduct && selectedProduct.props.isBought ? ' buttonDisabled' : '')}
                            onClick={(e) => { this.handleAddToCart('baseProducts') }}
                        >
                            <i className="bi bi-cart-plus"></i><span>{t('buy_add_to_cart')}</span>
                        </div>
                    </div>
                </div>

                {/* Expansion Products Carousel */}
                {expansions.length > 0 &&    (
                    <div className='buy_expansions_container'>
                        <h2 className='p-2'>{t('buy_latest_expansions')}</h2>
                        <CardList className='d-grid' itemsPerPage={3} items={expansions} cards={this.props.cards} onAddToCart={this.addToCart} />
                    </div>
                )}

                {/* Cart Container */}
                <div className='buy_list_buy_container'>
                    <div className='buy_list_background_container'>
                        {/* Added-to-Cart list */}
                        <h5 className='text-white'>{t('buy_cart_heading')}</h5>
                        <div className='d-flex flex-wrap buy_list_bought'>{bought}</div>
                        {/* <Cart items={cart} onRemove={this.removeFromCart}/> */}
                        <div className='buy_list_background_text'>
                            {price != 0 ?
                                t('buy_total_price') + ':' + price + 'CHF'
                                :
                                t('buy_empty_cart')
                            }
                        </div>
                    </div>
                    <div
                        className='buy_list_button_valider_container'
                    >
                        <div
                            className='buy_list_button_valider_text button buttonShadows gradientOrangePink buy_list_button_valider_image'
                            onClick={this.handleToCheckout}
                        >
                            {t('buy_to_checkout')}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default withDiscount(Buy)

