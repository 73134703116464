/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import Image from 'react-bootstrap/Image'
import ImageWebp from './ImageWebp';
import { withTranslation } from 'react-i18next';




class AltarCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        }
        this.handleShowDialog = this.handleShowDialog.bind(this);
    }

    handleShowDialog() {
        this.state.isOpen = !this.state.isOpen
        this.props.handler(this)
    };

    render() {
        var valReturn = []
        // open fullScreen depending on Cards or product preview
        const showDialog = this.props.handler !== undefined ? this.handleShowDialog : null

        const card = this.props
        const imageName = card.alt

        if (this.state.isOpen) {
            valReturn.push(
                <>
                    <ImageWebp className='popup' object='products' pulse={card.pulse} image={imageName} onClick={this.handleShowDialog} alt={card.title} />
                </>
            )
        }
        else {
            const pulseClassName = this.props.pulse !== undefined ? ' card_' + this.props.pulse : ''
            valReturn.push(
                <div className='testtttt'>
                    {showDialog !== null && <div className='cardNameRectangle centerText'>
                        <p> {this.props.title} </p>
                    </div>}
                    <ImageWebp className={"cardImage" + pulseClassName} object='products' pulse={card.pulse} image={imageName} onClick={showDialog} alt={card.title} />
                    {/* <Image fluid className={"cardImage" + pulseClassName} src={this.props.image} alt={card.title} onClick={showDialog} /> */}
                </div>
            )
        }
        return valReturn
    }
}
export default withTranslation('cards')(AltarCard);
