import React, { useState } from 'react';
import { Modal, Card, CardGroup, Badge, Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';

import AltarCard from './card';

import { t } from 'i18next';
import ImageWebp from './ImageWebp';

function CardList({ itemsPerPage, items, cards, onAddToCart }) {

    // date Novelty Logic
    const currentDate = new Date()
    const oneMonthAgo = new Date()
    // Taking date a month ago - May be changed
    oneMonthAgo.setMonth(currentDate.getMonth() - 1)

    // modal logic
    const [isShown, setVisibility] = useState(false);
    const [modalData, setModelData] = useState(null);
    const [modalCards, setModelCards] = useState([])

    // pagination logic
    const [currentPage, setCurrentPage] = useState(1);
    const pages = Math.ceil(items.length / itemsPerPage)

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem); // expansions products
    // grouping logic
    const expansions = new Set(currentItems.map(p => p.type))
    const expArr = Array.from(expansions)

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < pages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const test = (
        <div className="cards_slider">
            <div className="cards_group">
                <div className="card"></div>
                <div className="card"></div>
            </div>
            <div className="cards_group">
                <div className="card"></div>
            </div>
        </div>
    )

    return (
        <>
            <div className="cardwrapper">
                <div className="buy_expansions_cardslider d-flex justify-content-lg-around">
                    {expArr.map(exp => (
                        <CardGroup className='buy_expansions_cardsgroup'>
                        {/* <Card.Header className='w-100 text-center'>{exp}</Card.Header> */}
                            {currentItems.filter(p => p.type === exp).map((product) => (
                                <Card key={`${product.id}`} className='buy_expansion_cards bg-transparent text-white h-100' style={{ "maxWidth": "18rem" }}>
                                    <div className="position-relative text-center">
                                        <ImageWebp width="40%" className="card-img-top" object='pulses' image={product.image} alt={product.image} onError={(e) => { e.target.src = require('../img/icons/sample.png') }} />
                                        {/* Displaying 'New' Badge if expansion release up to 1 month ago */}
                                        {product.date >= oneMonthAgo && product.date <= currentDate && (
                                            <Badge className='buy_expansion_cards_badge' bg="danger">{t('buy_new_badge')}</Badge>
                                        )}
                                    </div>
                                    <Card.Body>
                                        <Card.Title className='buy_expansion_cards_title'>{product.title}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-light">{product.type}</Card.Subtitle>
                                        <Card.Text className='buy_expansion_cards_text'>
                                            {product.description}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Body>
                                        <button
                                            className='mb-2 buy_expansion_modal_link'
                                            onClick={() => {
                                                const cardList = cards.filter(c => c.product === product.id)
                                                setModelCards(cardList)
                                                setVisibility(true);
                                                setModelData(product);
                                            }}
                                        >
                                            {t('buy_see_more')}
                                        </button>
                                        <div className='d-inline float-end'><span className='buy_expansion_cards_base_price text-white-50'>CHF {product.cost}</span><span className="buy_expansion_cards_discounted_price">CHF {product.cost}</span></div>
                                    </Card.Body>
                                    <Card.Body>
                                        <div className='button gradientOrange buttonRadius p-2' onClick={() => onAddToCart(product.id)}>{t('buy_add_to_cart')}</div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <small className="text-light">{t('buy_release_text') + ' ' + product.startdate}</small>
                                    </Card.Footer>
                                </Card>
                            ))}
                        </CardGroup>
                    ))}
                </div>

            </div>
            {modalData !== null && <Modal
                show={isShown}
                onHide={() => setVisibility(false)}
                fullscreen="md-down"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName='buy_modal_expansion'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('buy_modal_expansion')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-bottom'>
                    <Row className='modal_expansion_info'>
                        <Col xs={6} md={4} className='mx-auto'>
                            <ImageWebp width="40%" className="modal_product_img" object='pulses' image={modalData.image} alt={modalData.image} onError={(e) => { e.target.src = require('../img/icons/sampleimg.png') }} />
                        </Col>
                        <Col xs={12} md={8}>
                            <h2>{modalData.title}</h2>
                            <p>{modalData.description}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                {modalCards.length > 0 ?
                    <>
                        <Modal.Body className='modal_expansion_cards'>
                            <h5>{t('buy_popover_header')}</h5>
                            <div className='buy_product_cardsBox'>
                                {modalCards.map(c => {
                                    return (<AltarCard
                                        key={'p_' + c.image}
                                        title={c.title}
                                        text={''}
                                        image={c.image}
                                        alt={c.image}
                                        pulse={c.pulse}
                                    />)
                                })}
                            </div>
                        </Modal.Body>
                    </>
                    :
                    <Modal.Body className='modal_expansion_cards'>
                        <div className='buy_product_cardsBox'>
                            {t('buy_modal_noExp_cards')}
                        </div>
                    </Modal.Body>
                }

                <Modal.Footer>
                    <div className='button gradientOrange buttonRadius buy_modal_add_to_cart_button' onClick={() => {
                        onAddToCart(modalData.id)
                        setVisibility(false)
                    }}
                    >
                        {t('buy_add_to_cart')}
                    </div>
                    <Button variant="danger" onClick={() => setVisibility(false)}>{t('modal_close')}</Button>
                </Modal.Footer>
            </Modal>}
            {/* Pagination Controls */}
            <div className='d-flex justify-content-center'>
                <ul className="pagination mt-3">
                    {currentPage !== 1 &&
                        <li class="page-item">
                            <button onClick={() => prevPage()} class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </button>
                        </li>}
                    {Array.from({ length: pages }).map((_, index) => (
                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                            <button onClick={() => paginate(index + 1)} className="page-link">
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    {currentPage !== pages &&
                        <li class="page-item">
                            <button onClick={() => nextPage()} class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </button>
                        </li>}
                </ul>
            </div>
        </>
    );
}

export default CardList