import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Image from 'react-bootstrap/Image'

// import right from '../img/melgraphics/droite_univers.png';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


function Tournoi({ onFormatDate }) {
    const { t } = useTranslation(['events']);
    const [events, setEvents] = useState([])
    const [mounted, setMounted] = useState(false)

    var lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : i18n.resolvedLanguage

    useEffect(() => {
        let mounted = true
        // console.log(mounted)
        if (mounted) {
            // console.log(mounted)
            fetchEvents()
        }

        return () => {
            mounted = false
            setMounted(false)
        }
    }, [])

    async function fetchEvents() {
        await fetch('/api.php', {
            method: 'POST',
            body: JSON.stringify({
                action: 'getEvents'
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server gave Error:' + response.status);
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data) {
                    // console.log(data)
                    if (data.events) {
                        // console.log(data.events)
                        
                        // set Events in state
                        setEvents(data.events)
                    } else {
                        //set error
                    }
                }
            })
            .catch(error => {
                console.error('Events could not be fetched:', error)
            })
    }

    /** onFormatDate struture  : formatDate(start_date:Date, displayHour=false:bool, compareTo=false:bool|string) 
     * displayHour  : for showing only date or includin hour
     * compareTo    : expects start_date to compare. If same day, only displays hour. 
     *                  Example: 2024-08-13 15:00 - 2024-08-13 17:00 => [formattedDate] 15:00 - 17:00
    */
    return (
        <div className="tournoi_grid">
            <div className='tournoi_container tournoi_image'>
                <Container className='tournoi_text d-flex align-items-center flex-column justify-content-center'>
                    <h1>{t('upcoming_events')}</h1>
                    {events.length > 0 ?
                        (
                            <table className='fw-light fs-5 w-auto d-block overflow-y-auto' style={{ 'height': '250px' }}>
                                <thead><tr><th>{t('events_date')}</th><th>{t('events_event')}</th><th>{t('events_address')}</th></tr></thead>
                                <tbody>
                                    {events.map((event) => {
                                        let endDate = event.end_date !== null ? onFormatDate(event.end_date, true, event.start_date) : ''
                                        return (
                                            <tr key={'event_' + event.id}>
                                                <td className='text-start'>{onFormatDate(event.start_date)}{endDate !== '' ? ` - ${endDate}` : ''}</td><td>{lng == 'fr' ? event.fr_name : event.name}</td><td className='text-end'><a href={event.link_location}>{event.Location}</a></td><td className='text-end'>{event.note && '|'} {event.note}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )
                        :
                        <div className='fw-light fs-5'>{t('noEvent')}</div>
                    }
                </Container>
            </div>
        </div >
    )
}

export default Tournoi

