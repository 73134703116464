import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Menu from "./menu";
import ReturnButton from "./returnButton";

// Translations Module
import { useTranslation } from "react-i18next";
import Buybutton from "./buybutton";
import ImageWebp from "./ImageWebp";
import { useNavigate } from "react-router-dom";

const FreeDownload = ({ products = [] }) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const baseProducts = products.filter(p => p.type === 'Base')
    const pulses_t = t("pulsesInfos", { ns: "cards", returnObjects: true });
    const [selectedPulses, setSelectedPulses] = useState([]);
    const [loading, setLoading] = useState(false);

    const isVerified = localStorage.getItem('verified') && localStorage.getItem('verified') == 'true' ? true : false


    useEffect(() => {
        if (!isVerified) {
            navigate('/')
        }
    }, []);


    const downloadPDF = async () => {
        if (selectedPulses.length !== 0) {
            setLoading(true);

            const statusLoggedIn = localStorage.getItem("token") != null ? true : false;
            const lng = i18n.resolvedLanguage ? i18n.resolvedLanguage.slice(0, 2) : 'en';

            fetch("/api.php", {
                method: "POST",
                body: JSON.stringify({
                    action: "download",
                    items: selectedPulses,
                    email: "",
                    pseudo: "",
                    lang: lng,
                    isLoggedIn: statusLoggedIn,
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        console.log("Response status:", response.status);
                        console.log("Response body:", response.type());
                        throw new Error("Network response was not ok");
                    }
                    return response.blob();
                })
                .then(blob => {
                    var file = window.URL.createObjectURL(blob);
                    window.location.assign(file);
                })
                .catch(error => {
                    console.error("There has been a problem with your fetch operation:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const onPulseClick = id => e => {
        var select = selectedPulses;
        if (e.target.checked) {
            select.push(id);
        } else {
            const index = select.indexOf(id);
            if (index > -1) {
                select.splice(index, 1);
            }
        }
        setSelectedPulses(select);
        // console.log(selectedPulses);
    }

    // console.log(baseProducts)

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t("page_download")} - Altar</title>
            </Helmet>
            <Menu />
            <div className="grid download_grid">
                <ReturnButton to='/' />
                <div className="selection_container grid_one_col">
                    <div className="pulse_grid boxShadows">
                        {baseProducts && baseProducts.length > 0 ? (
                            baseProducts.map(pulseitem => {
                                let pulseClassName = pulseitem.title;
                                pulseClassName = pulseClassName.toString().toLowerCase();
                                return (
                                    <div className={pulseClassName} key={pulseitem.id}>
                                        <label htmlFor={pulseClassName + "_btn"}>
                                            <ImageWebp
                                                className={pulseClassName + "_image"}
                                                image={pulseitem.image}
                                                object="pulses"
                                                alt={pulseitem.image}
                                                title={pulseitem.title}
                                            />
                                            <div className={pulseClassName + "_btn_container d-flex justify-content-end align-items-center"}>
                                                <span className="w-100 text-light text-center">{pulseitem.title}</span>
                                                <input
                                                    type="checkbox"
                                                    className={pulseClassName + "_btn flex-shrink-1"}
                                                    id={pulseClassName + "_btn"}
                                                    name={pulseClassName + "_btn"}
                                                    value={pulseClassName + "_btn"}
                                                    onChange={onPulseClick(pulseitem.id)}
                                                    title={t("account_download_checkbox", { pulse: pulseitem.title })}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                )
                            })
                        ) :
                            (
                                <div>{t('buy_no_products')}</div>
                            )
                        }
                    </div>

                    <div className="download_container">
                        <p className="textShadows">{t("account_download_files")}</p>
                        <div className="download_txt gradientOrange buttonShadows" onClick={downloadPDF}>
                            {loading ? (<div>{t("account_download_inprogress")}</div>)
                                : (<span><i className="bi bi-download"></i>{t("account_download")}</span>)}
                        </div>
                        {loading ? (<div className="lds-dual-ring"></div>) : (<div></div>)}
                        <p className="textShadows" style={{ color: "white" }}>
                            {t("account_download_fail")}
                        </p>
                        <p className="textShadows" style={{ color: "white" }}>
                            {t("account_download_message")}
                        </p>
                    </div>
                </div >

                <div className="download_cta gradientOrangePink buttonRadius py-5 p-md-5">
                    <span><i className="bi bi-plus-slash-minus px-2"></i>{t('download_cta_pre_title')}</span>
                    <h3>{t('download_cta_what_you_get')}</h3>
                    <table>
                        <tr>
                            <th className="label"></th>
                            <th className="product_free">{t('download_cta_freeversion')}</th>
                            <th className="product_paid">{t('download_cta_paidversion')}</th>
                        </tr>
                        <tr>
                            <td className="label">{t('download_cta_tablerow1')}</td>
                            <td className="product_free text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                            <td className="product_paid text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                        </tr>
                        <tr>
                            <td className="label">{t('download_cta_tablerow2')}</td>
                            <td className="product_free text-center"><i className="bi bi-x-lg download_crossicon"></i></td>
                            <td className="product_paid text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                        </tr>
                        <tr>
                            <td className="label">{t('download_cta_tablerow3')}</td>
                            <td className="product_free text-center"><i className="bi bi-x-lg download_crossicon"></i></td>
                            <td className="product_paid text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                        </tr>
                        <tr>
                            <td className="label">{t('download_cta_tablerow4')}</td>
                            <td className="product_free text-center"><i className="bi bi-x-lg download_crossicon"></i></td>
                            <td className="product_paid text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                        </tr>
                    </table>
                    <hr />
                    <Buybutton />
                </div>
            </div >
        </>
    );
};

export default FreeDownload;