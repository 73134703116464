/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the return button
 *
 * @author Jonathan Buso
 * @since 30.08.2023
 */
import React, { Children } from 'react'

import { useNavigate } from 'react-router-dom';

// Translations
import { useTranslation } from 'react-i18next';

import { useDiscount } from '../context/DiscountContext';


export default function ReturnButton({ to = -1, customClass = null, children = null }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div onClick={() => navigate(to)} className={`${customClass} button gradientPurpleRed buttonRadius buttonShadows`}>{ children !== null ? children : t('return_button') }</div>
    )
}

