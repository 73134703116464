/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the website
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

// Custom CSS
import './css/index.css';

import './css/previewCards.css';
import './css/overlayForm.css';
import './css/description.css';
import './css/buy.css'
import './css/menu.css'
import './css/login.css'
import './css/account_download.css';
import './css/tournoi.css'
import './css/register.css'
import './css/imgGen.css'
import './css/checkout.css'
import './css/validation.css'

// Bootstrap & Icons CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { StrictMode, Suspense, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Layout from "./pages/layout";
import Home from "./pages/home";
import Cards from "./pages/cards";
import Checkout from "./pages/checkout";
import InsertPseudo from "./pages/insertPseudo";
import Validation from './pages/validation';
import Account from './pages/account';
import FreeDownload from './pages/download'
import TermsOfUse from './pages/termsOfUse';
import NotFound from './pages/notFound'

// DiscountContext Provider to wrap the App
import { DiscountProvider } from './context/DiscountContext'; // Cart and price related propoerties can be accessed throughout App
import { AuthProvider } from './context/AuthContext'; // Authentication properties can be accessed from anywhere in App

// Translation module initialization & settings
import './i18n';
import { useTranslation } from 'react-i18next';

export default function App() {
  const { t, i18n } = useTranslation()
  const [products, setProducts] = useState([])
  //   const [loading, setLoading] = useState(true)

  // on component Mount
  useEffect(() => {
    // console.log('fetching products')
    fetchTranslatedProducts();
    i18n.on('languageChanged', fetchTranslatedProducts);

    return () => {
      i18n.off('languageChanged', fetchTranslatedProducts);
      console.log('app unmounted')
    };
  }, []);


  async function fetchTranslatedProducts() {
    const products_t = t('pulsesInfos', { ns: 'cards', returnObjects: true });

    await fetch('/api.php', {
      method: 'POST',
      body: JSON.stringify({
        action: 'getAvailableProduct'
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Server gave Error:' + response.status);
        } else {
          return response.json()
        }
      })
      .then((data) => {
        if (data) {
          // console.log(data)
          if (data.AvailableProduct) {
            let expansionsValues = []
            // console.log('fetch data: ', data.AvailableProduct)

            // filtering products to base decks & expansions
            const productsData = data.AvailableProduct.filter(p => p.ename === 'Base')
            const expansionsData = data.AvailableProduct.filter(e => e.ename !== 'Base')

            // dummy expansions - ONLY FOR TESTING
            // const expansionsDummy = [
            //   {
            //     id: 7,
            //     image: 'death.png',
            //     p_en_name: 'Court of Fallen Souls',
            //     p_fr_name: 'Cour des Âmes Déchus',
            //     p_fr_description: 'Complétez votre arsenal de la mort avec des puissantes carte des Âmes Déchues',
            //     p_en_description: 'Complete your death arsenal with powerful Fallen Souls cards',
            //     cost: 1400,
            //     ename: 'Second',
            //     start_date: '2023-09-16'
            //   },
            //   {
            //     id: 8,
            //     image: 'order.png',
            //     p_fr_name: 'Tribunal Archerande',
            //     p_en_name: 'Archerande Tribunal',
            //     p_fr_description: "Enrichissez votre collection de cartes du célèbre Tribunal Archerande",
            //     p_en_description: 'Expand your collection of cards from the famous Archerande Tribunal',
            //     cost: 1400,
            //     ename: 'First',
            //     start_date: '2023-09-06'
            //   },
            //   {
            //     id: 9,
            //     image: 'lies.png',
            //     p_fr_name: 'Légion des Ombres Éternelles',
            //     p_en_name: 'Legion of Eternal Shadows',
            //     p_fr_description: 'Rejoignez la Légion des Ombres Éternelles et renforcez votre deck avec des cartes redoutables.',
            //     p_en_description: 'Join the Legion of Eternal Shadows and strengthen your deck with formidable cards.',
            //     cost: 1400,
            //     ename: 'First',
            //     start_date: '2023-09-08'
            //   },
            //   {
            //     id: 10,
            //     image: 'life.png',
            //     p_fr_name: 'Requiem des Guérisseurs Octecite',
            //     p_en_name: 'Requiem of Octecite Healers',
            //     p_fr_description: 'Soignez vos blessures et renforcez vos alliés avec les cartes du Requiem des Guérisseurs Octecite, une extension dédiée à la guérison et à la vitalité',
            //     p_en_description: 'Heal your wounds and empower your allies with cards from the Requiem of Octecite Healers, an expansion dedicated to healing and vitality',
            //     cost: 1400,
            //     ename: 'Third',
            //     start_date: '2023-10-05'
            //   },
            //   {
            //     id: 11,
            //     image: 'truth.png',
            //     p_fr_name: 'Codex des Secrets Révélés',
            //     p_en_name: 'Codex of Revealed Secrets',
            //     p_fr_description: 'Découvrez la vérité cachée et révelez les cartes de votre adversaires avec les cartes du Codex des Secrets Révélés, une extension dédiée à la connaissance et à la révélation',
            //     p_en_description: 'Uncover hidden truths and reveal your opponent\'s cards with cards from the Codex of Revealed Secrets, an expansion dedicated to knowledge and revelation',
            //     cost: 1400,
            //     ename: 'Third',
            //     start_date: '2023-10-01'
            //   },
            //   {
            //     id: 12,
            //     image: 'order.png',
            //     p_fr_name: 'Tribunal Archerande',
            //     p_en_name: 'Archerande Tribunal',
            //     p_fr_description: "Enrichissez votre collection de cartes du célèbre Tribunal Archerande",
            //     p_en_description: 'Expand your collection of cards from the famous Archerande Tribunal',
            //     cost: 1400,
            //     ename: 'First',
            //     start_date: '2023-09-05'
            //   },
            //   {
            //     id: 13,
            //     image: 'lies.png',
            //     p_fr_name: 'Légion des Ombres Éternelles',
            //     p_en_name: 'Legion of Eternal Shadows',
            //     p_fr_description: 'Rejoignez la Légion des Ombres Éternelles et renforcez votre deck avec des cartes redoutables.',
            //     p_en_description: 'Join the Legion of Eternal Shadows and strengthen your deck with formidable cards.',
            //     cost: 1400,
            //     ename: 'Second',
            //     start_date: '2023-09-13'
            //   },
            //   {
            //     id: 14,
            //     image: 'life.png',
            //     p_fr_name: 'Requiem des Guérisseurs Octecite',
            //     p_en_name: 'Requiem of Octecite Healers',
            //     p_fr_description: 'Soignez vos blessures et renforcez vos alliés avec les cartes du Requiem des Guérisseurs Octecite, une extension dédiée à la guérison et à la vitalité',
            //     p_en_description: 'Heal your wounds and empower your allies with cards from the Requiem of Octecite Healers, an expansion dedicated to healing and vitality',
            //     cost: 1400,
            //     ename: 'Second',
            //     start_date: '2023-09-13'
            //   },
            //   // {
            //   //     id: 15,
            //   //     image: 'truth.png',
            //   //     p_fr_name: 'Codex des Secrets Révélés',
            //   //     p_en_name: 'Codex of Revealed Secrets',
            //   //     p_fr_description: 'Découvrez la vérité cachée et révelez les cartes de votre adversaires avec les cartes du Codex des Secrets Révélés, une extension dédiée à la connaissance et à la révélation',
            //   //     p_en_description: 'Uncover hidden truths and reveal your opponent\'s cards with cards from the Codex of Revealed Secrets, an expansion dedicated to knowledge and revelation',
            //   //     cost: 1400,
            //   //     ename: 'Third',
            //   //     start_date: '2023-09-21'
            //   // }
            // ]

            const productsValues = formatProducts(productsData)
            // console.dir(allProducts)
            // drawing base products selection component
            // this.setProducts(productsData, 'products', true)

            // filtering all products to base expansions

            // this.setState({ expansions: expDummy })

            // this.setProducts(expansionsDummy, 'expansions', true) // use with slider

            if (expansionsData.length > 0) {
              expansionsValues = formatProducts(expansionsData, true) // formatting expansion and sorting them by date
            }

            const allProducts = productsValues.concat(expansionsValues)
            setProducts(allProducts)

          } else {
            //set error
          }
        }
      })
      .catch(error => {
        console.error('Products could not be fetched:', error)
        setProducts(Object.values(products_t))
      })
  }

  const formatDate = (strDate, displayHour = true) => {
    const dateStr = strDate;

    // console.log(dateStr)
    // Step 1: Parse the date string into a Date object - Date format : YYYY-MM-DD hh-mm-ss
    const date = new Date(dateStr);

    // Step 2: Get the user's preferred language
    const userLanguage = i18n.resolvedLanguage.slice(0, 2)

    let year = date.toLocaleDateString(userLanguage, { year: "numeric" })
    let month = date.toLocaleDateString(userLanguage, { month: "short" })
    let weekday = date.toLocaleDateString(userLanguage, { weekday: "short" })
    let day = date.toLocaleDateString(userLanguage, { day: "2-digit" })
    let hour = date.toLocaleTimeString(userLanguage, { hour: "2-digit", hour12: false, minute: "2-digit" })

    let hasHour = displayHour ? `à ${hour}` : ''
    let weekdayCap = weekday.charAt(0).toUpperCase() + weekday.slice(1)

    // Step 3: Format the date according to user language
    let formattedDate = `${weekdayCap} ${day} ${month} ${year} ${hasHour}`

    if (userLanguage === "en") {
      const hour = date.toLocaleTimeString(userLanguage, { hour: "2-digit", hour12: true, minute: "2-digit" });
      hasHour = displayHour ? `at ${hour}` : ''
      formattedDate = `${weekdayCap}, ${month} ${day}, ${year} ${hasHour}`;
    }

    return formattedDate
  }

  function formatProducts(array, sortByDate = false) {
    const lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en'
    var formatedArray = []


    // setting dynamic property name for potential future language implementation - provided the entry exists in the database and is fetched
    const p_lng_name = `p_${lng}_name`
    const p_lng_description = `p_${lng}_description`

    array.forEach(item => {
      const pname = item[p_lng_name]
      const pdescription = item[p_lng_description]
      const ptype = item.ename
      const pcost = (item.cost / 100)
      const pdateString = item.start_date

      const product = {}

      product.id = item.id
      product.image = item.image
      product.title = pname
      product.description = pdescription
      product.cost = pcost
      product.type = ptype
      product.date = new Date(pdateString)
      product.startdate = formatDate(item.start_date, false) // onFormatDate( array, displayHour = true )

      formatedArray.push(product)

    })

    if (sortByDate) {
      formatedArray.sort((a, b) => b.date - a.date) // sorting expansions by descending Date (more recent first)
    }

    return formatedArray
  }


  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Altar</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home products={products} />} />
          <Route path="cards" element={<Cards />} />
          <Route path="checkout" element={<Checkout allProducts={products} />} />
          <Route path="insert" element={<InsertPseudo />} />
          <Route path="account" element={<Account />} />
          <Route path="download" element={<FreeDownload products={products} />} />
          <Route path="validation" element={<Validation />} />
          <Route path='terms-of-use' element={<TermsOfUse />} />

          <Route path="page-not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="page-not-found" replace={true} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const compose = (providers) =>
  providers.reduce((Prev, Curr) => ({ children }) => (
    <Prev>
      <Curr>{children}</Curr>
    </Prev>
  ));

// Merging multple providers when using context for better readability
const Providers = compose([
  DiscountProvider,
  AuthProvider
])

const container = document.getElementById("app")
const root = createRoot(container);

root.render(
  <StrictMode>
    <Suspense>
      <Providers>
        <App />
      </Providers>
    </Suspense>
  </StrictMode>
);

