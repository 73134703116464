import i18n from 'i18next'
import React from 'react'

function toWebpImage(image, newExtension = 'webp') {
    // // Split the image by the last slash to separate the directory and file parts
    const lastSlashIndex = image.lastIndexOf('/');
    // const directory = image.substring(0, lastSlashIndex + 1); // Include the trailing slash
    const fileWithExtension = image.substring(lastSlashIndex + 1);
    // console.log(fileWithExtension)
    // // Split the fileWithExtension by the last dot to separate the name and extension
    const parts = fileWithExtension.split('.');

    // Ensure that there is at least one dot in the string (to have an extension)
    if (parts.length > 1) {
        // Remove the last part (jpg or png extension)
        parts.pop();
    }

    // Handle the case where React adds a hash to the filename
    // Example: 'truth.e589f92' => 'truth'. does nothing if no hash
    let newName = parts.join('.');

    // Check if there's a hash in the filename
    const hashIndex = newName.lastIndexOf('.');
    if (hashIndex !== -1) {
        const hash = newName.substring(hashIndex); // Get the hash part
        // newName = newName.substring(0, hashIndex) + `${hash}`; // includes hash
        newName = newName.substring(0, hashIndex);
    }

    const newImageString = `${newName}.${newExtension}`;

    return newImageString;
}

/* ImageWebp acts as an <img /> with the following exceptions:
* 1. image refers to the STRING of the image
* 2. object refers to the project specific parent item to reference image filepath
* 3. pulse represents the child folder to reference image filepath
*/
const ImageWebp = ({ image = '', object = 'cards' || 'pulses' || 'products' || 'graphics' || 'tuto' || 'icons' || 'actions', pulse, title = null, alt = '', ...props }) => {
    let lng = i18n.resolvedLanguage ? i18n.resolvedLanguage.slice(0, 2) : i18n.languages[0]
    // Requiring image based on src string since it has to be processed for React
    let mimeType = 'image/webp'
    let webpString = ''
    let imgWebp = ''
    let imgAlt = ''

    try {
        switch (object) {
            case 'cards':
                // getting .webp version of the image
                imgAlt = require(`../img/pulses/${pulse}/sample/${lng}/${image}`)
                webpString = toWebpImage(image)
                imgWebp = require(`../img/pulses/${pulse}/sample/${lng}/${webpString}`)
                break;
            case 'pulses':
                // getting .webp version of the image
                imgAlt = require(`../img/pulses/${image}`)
                webpString = toWebpImage(image)
                imgWebp = require(`../img/pulses/${webpString}`)
                break;
            case 'graphics':
                // getting .webp version of the image
                imgAlt = require(`../img/melgraphics/${image}`)
                webpString = toWebpImage(image)
                imgWebp = require(`../img/melgraphics/${webpString}`)
                break;
            case 'tuto':
                // getting .webp version of the image
                imgAlt = require(`../img/tuto_text/${image}`)
                webpString = toWebpImage(image)
                imgWebp = require(`../img/tuto_text/${webpString}`)
                break;
            case 'icons':
                // getting .webp version of the image
                imgAlt = require(`../img/icons/${image}`)
                webpString = toWebpImage(image)
                imgWebp = require(`../img/icons/${webpString}`)
                break;
            case 'actions':
                // getting .webp version of the image
                imgAlt = require(`../img/homeactions/${image}`)
                webpString = toWebpImage(image)
                imgWebp = require(`../img/homeactions/${webpString}`)
                break;
            case 'decks':
                // getting .webp version of the image
                imgAlt = require(`../img/pulses/pulse_backup/${image}`)
                webpString = toWebpImage(image)
                imgWebp = require(`../img/pulses/pulse_backup/${webpString}`)
                break;
            case 'products':
                // getting .webp version of the image
                imgAlt = require(`../img/products/${pulse}_base/sample/${lng}/${image}`)
                webpString = toWebpImage(image)
                imgWebp = require(`../img/products/${pulse}_base/sample/${lng}/${webpString}`)
                break;
            default:
                break;
        }
    } catch (error) {
        // loading alternative image when webp does not exist
        // console.log(`${error}. The alternative image will be loaded instead.`)
        imgWebp = imgAlt !== '' && imgAlt
        mimeType = 'image/jpeg'
    }

    const { className, onClick, width } = props
    return (
        <picture className={className} onClick={onClick}>
            <source srcSet={imgWebp} type={mimeType} onError={(e) => { e.target.srcSet = null }} />
            <img className='img-fluid' src={imgAlt} title={title} alt={alt} width={width} />
        </picture>
    )
}
export default ImageWebp