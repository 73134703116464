import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation, useNavigate } from 'react-router-dom'

import bouton_buy from '../img/melgraphics/boutton_buy.png';

import button_twitch from '../img/melgraphics/boutton_twitch.png';
import button_discord from '../img/melgraphics/boutton_discord.png';

import { useTranslation } from 'react-i18next';
import ImageWebp from './ImageWebp';
import OverlayForm from './overlayForm';

const Accueil = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [showOverlay, setShowOverlay] = useState(false);

    // URL handling
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');
    const verify_key = searchParams.get('verify_key');

    let downloadClick = localStorage.getItem('verified') && localStorage.getItem('verified') == 'true' ? () => navigate('/download') : () => setShowOverlay(true)

    // show overlay if coming from link
    useEffect(() => {
      if(emailParam !== null && emailParam !== '' && emailParam !== 0 & verify_key !== null && verify_key !== '') {
        setShowOverlay(true)
      }
    }, [])   

    return (
        <div className="container text-center accueil">
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <ImageWebp fluid className="d-block mx-auto" object="graphics" image='characters.png' alt='characters.png' />
                    </Col>
                    <Col md={6}>
                        <Row className='position-relative mx-auto'>
                            {/* Altar Logo */}
                            <ImageWebp className="d-block" object="graphics" image='accueil_logo.png' alt='accueil_logo.png' />
                            <h3 className="accueil_logo_text">{t('accueil_title')}</h3>
                        </Row>

                        <Row>
                            <div className="purchasewrp">
                                <div className='topBuyButton'>
                                    <button onClick={downloadClick} className='freedownload_link button gradientPurpleRed buttonRadius buttonShadows mx-auto'>
                                        <div className="freedownload_btn">{t('accueil_button_freedownload')}</div>
                                    </button>
                                    { showOverlay ? <OverlayForm onClose={() => setShowOverlay(false)} /> : null }
                                    {/* <Link to="/download" className='freedownload_link button gradientPurpleRed buttonRadius buttonShadows mx-auto'>
                                        <div className="freedownload_btn">{t('accueil_button_freedownload')}</div>
                                    </Link> */}
                                    <Row>
                                        <Col lg={6}>
                                            <a href="#videos" className='what_am_i_link button gradientOrangePink buttonradius buttonShadows mx-auto'>
                                                <div className="what_am_i_btn">{t('accueil_button_what_am_i')}</div>
                                            </a>
                                        </Col>
                                        <Col lg={6}>
                                            <a href="#purchase" className='buy_btn'>
                                                <ImageWebp fluid object="graphics" image='boutton_buy.png' alt='boutton_buy.png' />
                                                <h1 className="buy_btn_text btn_text">{t('accueil_button_buy')}</h1>
                                            </a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <Row className='socials m-auto'>
                                <Col lg={6}>
                                    <a href="https://discord.gg/N8TmZgqeTr" className='discord_btn'>
                                        <ImageWebp fluid object="graphics" image='boutton_discord.png' alt='boutton_discord.png' />
                                        <h2 className="btn_text">Discord</h2>
                                    </a>
                                </Col>
                                <Col lg={6}>
                                    <a href="https://www.twitch.tv/abalance_gaming" className='twitch_btn'>
                                        <ImageWebp fluid object="graphics" image='boutton_twitch.png' alt='boutton_twitch.png' />
                                        <h2 className="btn_text">Twitch</h2>
                                    </a>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Accueil;

// // Translation module
// import { t } from 'i18next';
// import { withTranslation } from 'react-i18next';
// import ImageWebp from './ImageWebp';

// class Accueil extends React.Component {
//     render() {

//         return (

//             <div className="container text-center accueil">

//                 <Container fluid>
//                     <Row>
//                         <Col md={6}>
//                             <ImageWebp fluid className="d-block mx-auto" object="graphics" image='characters.png' alt='characters.png' />
//                         </Col>
//                         <Col md={6}>
//                             <Row className='position-relative mx-auto'>
//                                 {/* Altar Logo */}
//                                 <ImageWebp fluid className="d-block" object="graphics" image='accueil_logo.png' alt='accueil_logo.png' />
//                                 <h3 className="accueil_logo_text">{t('accueil_title')}</h3>
//                             </Row>

//                             <Row>
//                                 <div className="purchasewrp">
//                                     <div className='topBuyButton'>
//                                         <Link to="/download" className='freedownload_link button gradientPurpleRed buttonRadius buttonShadows mx-auto'>
//                                             <div className="freedownload_btn">{t('accueil_button_freedownload')}</div>
//                                         </Link>

//                                         <Row>
//                                             <Col lg={6}>
//                                                 <a href="#videos" className='what_am_i_link button gradientOrangePink buttonradius buttonShadows mx-auto'>
//                                                     <div className="what_am_i_btn">{t('accueil_button_what_am_i')}</div>
//                                                 </a>
//                                             </Col>
//                                             <Col lg={6}>
//                                                 <a href="#purchase" className='buy_btn'>
//                                                     <ImageWebp fluid object="graphics" image='boutton_buy.png' alt='boutton_buy.png' />
//                                                     <h1 className="buy_btn_text btn_text">{t('accueil_button_buy')}</h1>
//                                                 </a>
//                                             </Col>
//                                         </Row>
//                                     </div>
//                                 </div>

//                                 <Row className='socials m-auto'>
//                                     <Col lg={6}>
//                                         <a href="https://discord.gg/N8TmZgqeTr" className='discord_btn'>
//                                             <ImageWebp fluid object="graphics" image='boutton_discord.png' alt='boutton_discord.png' />
//                                             <h2 className="btn_text">Discord</h2>
//                                         </a>
//                                     </Col>
//                                     <Col lg={6}>
//                                         <a href="https://www.twitch.tv/abalance_gaming" className='twitch_btn'>
//                                             <ImageWebp fluid object="graphics" image='boutton_twitch.png' alt='boutton_twitch.png' />
//                                             <h2 className="btn_text">Twitch</h2>
//                                         </a>
//                                     </Col>
//                                 </Row>
//                             </Row>
//                         </Col>
//                     </Row>


//                 </Container>
//             </div>
//         )
//     }

// }

// export default withTranslation()(Accueil)

